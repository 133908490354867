<template>
  <b-card class="border-0" no-body>
    <b-row v-for="(vidset, i) in videos" :key="'vidset-' + i">
      <b-col md="3" v-for="(vid, j) in vidset" :key="'vid-' + j">
        <training-video :title="vid.title" :src="getSrc(vid)" :poster="getPoster(vid)" />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';
import TrainingVideo from '@/views/private/help/TrainingVideo';
import commonDao from '@/dao/common_dao';
import { errorToastOptions } from '@/util/formatters';

@Component({
  components: {
    TrainingVideo
  }
})
export default class TrainingVideos extends Vue {
  manifest = [];

  get videos() {
    return this.manifest;
  }

  getSrc(vid) {
    return `/helpdocs/videos/${vid.folder}/${vid.src}`;
  }

  getPoster(vid) {
    return `/helpdocs/videos/${vid.folder}/${vid.poster}`;
  }

  async beforeRouteEnter(to, from, next) {
    store.commit('addBreadcrumb', Breadcrumb.create('Training Videos', null, true));
    next();
  }

  async mounted() {
    try {
      this.manifest = await commonDao.getVideosManifest();
    } catch (error) {
      this.$bvToast.toast(error.message, errorToastOptions);
    }
  }
}
</script>
<style scoped></style>
