<template>
    <b-card class="border-0 mb-2 text-center">
        <div>
            <video :controls="controls" :src="src" :poster="poster" preload="none">
                Your browser does not support embedded video.
            </video>
        </div>
        <div>
            <strong>{{title}}</strong>
        </div>
    </b-card>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class TrainingVideo extends Vue {
    @Prop({type: String, default: undefined}) title;
    @Prop({type: Number, default: 320}) width;
    @Prop({type: Number, default: 240}) height;
    @Prop({type: Boolean, default: true}) controls;
    @Prop({type: String}) src;
    @Prop({type: String}) poster;
}
</script>
<style scoped>
    video {
        width: 100%;
        max-width: 320px;
    }
</style>